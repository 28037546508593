import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";
import { IGlobalSearch } from "@app/shared/interfaces/global-search.interface";
import { ISearchHistory } from "@app/shared/interfaces/search-history.interface";
import { LocalStorageService } from "@app/shared/services/local-storage.service";
import { STORAGE_GLOBAL_SEARCH } from "@app/core/components/global-search/shared/global-search.const";
import { BehaviorSubject } from "rxjs";
import { sortByProperty } from "@app/core/utils/generate-time.cons";
import { removeDuplicate } from "@app/core/helper/array.helper";
import { IModule } from '@app/shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class GlobalSearchService {

  public keywordHistory: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public keyword$ = new BehaviorSubject<string>('');
  categoryType$ = new BehaviorSubject<IModule[]>([]);

  constructor(
    private http: HttpClient,
    private storage: LocalStorageService
  ) {
  }

  public searchGlobal(search: IGlobalSearch): Observable<any> {
    const payload: IGlobalSearch = {string: search.string, stage: search.stage}
    return this.http.post(`${environment.apiUrl}/search`,
      payload,
      { withCredentials: true });
  }

  public setSearchHistory(search: ISearchHistory): void {
    let keywords: ISearchHistory[] = [];
    let availableKeywords: any= this.storage.getKey(STORAGE_GLOBAL_SEARCH);
    let parseValues  = JSON.parse(availableKeywords) || [];

    if(availableKeywords) {
      keywords = parseValues;
      keywords.push(search)
      keywords = removeDuplicate(keywords, 'name');

      this.storage.setKey(STORAGE_GLOBAL_SEARCH, JSON.stringify(keywords))
      this.keywordHistory.next(keywords.slice(0, 3))
    } else {
      keywords.push(search)

      this.storage.setKey(STORAGE_GLOBAL_SEARCH, JSON.stringify(keywords))
      this.keywordHistory.next(keywords.slice(0, 3))
    }
  }

  public getSearchHistory(): void {
    const keywords = this.storage.getKey(STORAGE_GLOBAL_SEARCH);

    if(keywords) {
      const loadKeywords = JSON.parse(keywords).slice(0, 3);
      this.keywordHistory.next(sortByProperty(removeDuplicate(loadKeywords, 'name'), 'timestamp'));
    }
  }

  public deleteHistoryList(timestamp: number): void {
    const keywords = this.storage.getKey(STORAGE_GLOBAL_SEARCH);

    if(keywords) {
      const currentRecord = JSON.parse(keywords);
      const index = currentRecord.findIndex((keyword: ISearchHistory) => keyword.timestamp === timestamp);
      currentRecord.splice(index, 1)
      this.keywordHistory.next(currentRecord);
      this.storage.setKey(STORAGE_GLOBAL_SEARCH, JSON.stringify(currentRecord))
    } else {
      this.storage.clearKey(STORAGE_GLOBAL_SEARCH)
    }
  }
}
